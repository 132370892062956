import {
  CustomContentProps,
  SnackbarContent,
  enqueueSnackbar,
  useSnackbar,
} from "notistack";
import React, {
  Fragment,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import { ModalErrorMessagesType } from "../../util/CommonUtils";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Stack } from "@mui/material";

const StyledSnackbarCard = styled(Card)({
  "@media (min-width:600px)": {
    minWidth: "344px !important",
  },
});

interface Props extends CustomContentProps {
  errorObject: ModalErrorMessagesType;
}

const CustomErrorSnackBar = forwardRef<HTMLDivElement, Props>(
  ({ id, message, errorObject, ...props }, ref) => {
    const { closeSnackbar } = useSnackbar();
    const [copied, setCopied] = useState<boolean>(false);

    React.useEffect(() => {
      let timeout = !errorObject
        ? setTimeout(() => handleDismiss(), 3000)
        : undefined;

      return () => {
        if (timeout) clearTimeout(timeout);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      let timeout = copied
        ? setTimeout(() => setCopied(false), 3000)
        : undefined;

      return () => {
        if (timeout) clearTimeout(timeout);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [copied]);

    const copy = async () => {
      if (!errorObject?.correlationID || errorObject?.messages.length === 0)
        return;
      else
        try {
          const errorObj = {
            correlationID: errorObject?.correlationID,
            messages: errorObject?.messages,
          };
          await navigator.clipboard.writeText(JSON.stringify(errorObj));
          setCopied(true);
        } catch (err) {
          enqueueSnackbar("Failed to copy", {
            variant: "error",
          });
          console.log(err);
          setCopied(false);
        }
    };

    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    return (
      <SnackbarContent ref={ref} {...props}>
        <StyledSnackbarCard sx={{ backgroundColor: "#D32F2F" }}>
          <CardActions>
            <CancelRoundedIcon style={{ color: "#fff" }} />
            <Stack direction={"column"} display={"flex"} ml={0.5} mr={2}>
              <Typography variant="body2" color={"#fff"} ml={0.5}>
                {message}
              </Typography>
            </Stack>
            <div
              style={{
                marginLeft: "auto",
                ...(errorObject
                  ? {}
                  : { marginTop: "15px", marginBottom: "15px" }),
              }}
            >
              {errorObject ? (
                <Fragment>
                  {copied ? (
                    <IconButton
                      aria-label="Show more"
                      size="small"
                      style={{ color: "#fff" }}
                      onClick={copy}
                    >
                      <CheckCircleRoundedIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      aria-label="Show more"
                      size="small"
                      style={{ color: "#fff" }}
                      onClick={copy}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  )}

                  <IconButton
                    size="small"
                    onClick={handleDismiss}
                    style={{ color: "#fff" }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Fragment>
              ) : null}
            </div>
          </CardActions>
        </StyledSnackbarCard>
      </SnackbarContent>
    );
  }
);

export default CustomErrorSnackBar;