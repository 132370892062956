import React from "react";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import {
  formatDateForGrid,
  comparatorForDate,
  currencyFormatter,
  negativeValueColorFormatter,
  percentageFormatter,
} from "@genrecp/g2clientportal-common";
import { AgGridClasses } from "@genre/g2common-theme";
import {
  CellClassParams,
  ValueFormatterParams,
} from "ag-grid-community";
import { BillItemsType } from "../billDetailUtils";

interface Props {
  rowData: BillItemsType[];
}
function SubmitConfirmation({ rowData }: Props) {
  const [colDefs] = React.useState<ColDef<any>[]>([
    {
      field: "amountPaid",
      headerName: "Pay Amount",
      filter: "agNumberColumnFilter",
      valueGetter: function (params: any) {
        return `${currencyFormatter(params?.data?.amountPaid,true)}`;
      },
      cellStyle: (params:CellClassParams) => negativeValueColorFormatter(params.data?.amountPaid)
    },
    { field: "amountDueOverride", filter: "agNumberColumnFilter", hide:true },
    { field: "policyNumber", filter: "agTextColumnFilter" },
    { field: "insuredName", filter: "agTextColumnFilter", width: 340 },
    { 
      field: "grossDueAmount",
      filter: "agNumberColumnFilter",
      headerName:"Gross Prem Amt",
      valueGetter: function (params: any) {
        return `${currencyFormatter(params?.data?.grossDueAmount,true)}`;
      },
      cellStyle: (params:CellClassParams) => negativeValueColorFormatter(params.data?.grossDueAmount)
    },
    {
      field: "commissionRatePercent",
      filter: "agNumberColumnFilter",
      width: 240,
      valueFormatter: function (params:ValueFormatterParams) {return percentageFormatter(params.value)},
    },
    { 
      field: "commissionAmount",
      filter: "agNumberColumnFilter",
      valueGetter: function (params: any) {
        return `${currencyFormatter(params?.data?.commissionAmount,true)}`;
      },
      cellStyle: (params:CellClassParams) => negativeValueColorFormatter(params.data?.commissionAmount)
    },
    { 
      field: "netPremiumAmount",
      filter: "agNumberColumnFilter",
      valueGetter: function (params: any) {
        return `${currencyFormatter(params?.data?.netPremiumAmount,true)}`;
      },
      cellStyle: (params:CellClassParams) => negativeValueColorFormatter(params.data?.netPremiumAmount)
    },
    { 
      field: "netPremiumDueAmount",
      filter: "agNumberColumnFilter",
      headerName:"Current Amt Due",
      valueGetter: function (params: any) {
        return `${currencyFormatter(params?.data?.netPremiumDueAmount,true)}`;
      },
      cellStyle: (params:CellClassParams) => negativeValueColorFormatter(params.data?.netPremiumDueAmount)
    },
    {
      field: "dueDate",
      filter: "agDateColumnFilter",
      valueGetter: function (params: any) {
        return formatDateForGrid(params.data.dueDate);
      },
      filterParams: {
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          return comparatorForDate(filterLocalDateAtMidnight, cellValue);
        },
      },
    },
    { field: "daysOverdue", filter: "agNumberColumnFilter" },
    {
      field: "policyTransactionTypeCode",
      filter: "agTextColumnFilter",
      width: 240,
    },
    { field: "endNumber", filter: "agTextColumnFilter" },
    {
      field: "transactionEffectiveDate",
      filter: "agDateColumnFilter",
      valueGetter: function (params: any) {
        return formatDateForGrid(params.data.transactionEffectiveDate);
      },
      filterParams: {
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          return comparatorForDate(filterLocalDateAtMidnight, cellValue);
        },
      },
      width: 240,
    },
    {
      field: "policyEffectiveDate",
      filter: "agDateColumnFilter",
      valueGetter: function (params: any) {
        return formatDateForGrid(params.data.policyEffectiveDate);
      },
      filterParams: {
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          return comparatorForDate(filterLocalDateAtMidnight, cellValue);
        },
      },
    },
    {
      field: "policyExpirationDate",
      filter: "agDateColumnFilter",
      valueGetter: function (params: any) {
        return formatDateForGrid(params.data.policyExpirationDate);
      },
      filterParams: {
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          return comparatorForDate(filterLocalDateAtMidnight, cellValue);
        },
      },
    },
    { field: "gsmUWID", filter: "agTextColumnFilter" },
    { field: "otherChargeName", filter: "agTextColumnFilter" },
    { 
      field: "otherChargeAmount",
       filter: "agNumberColumnFilter",
      valueGetter: function (params: any) {
        return `${currencyFormatter(params?.data?.otherChargeAmount,true)}`;
      },
      cellStyle: (params:CellClassParams) => negativeValueColorFormatter(params.data?.otherChargeAmount)
   },
    { field: "clientLocationID", filter: "agTextColumnFilter" },
    { field: "grapSearchCode", filter: "agTextColumnFilter" },
    {
      field: "transactionRegistrationDate",
      filter: "agDateColumnFilter",
      valueGetter: function (params: any) {
        return formatDateForGrid(params.data.transactionRegistrationDate);
      },
      filterParams: {
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          return comparatorForDate(filterLocalDateAtMidnight, cellValue);
        },
      },
      width: 260,
    },
    {
      field: "billingDate",
      filter: "agDateColumnFilter",
      valueGetter: function (params: any) {
        return formatDateForGrid(params.data.billingDate);
      },
      filterParams: {
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          return comparatorForDate(filterLocalDateAtMidnight, cellValue);
        },
      },
    },
  ]);

  return (
    <div
      className={`ag-theme-alpine ${AgGridClasses["ag-theme-alpine"]}`}
      style={{ width: "100%", height: window.innerHeight - 280 }}
    >
      <AgGridReact
        rowHeight={25}
        animateRows={true}
        rowData={rowData}
        columnDefs={colDefs}
      />
    </div>
  );
}

export default SubmitConfirmation;
