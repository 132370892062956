import styled from "styled-components";
import { AxiosError } from "axios";

export const dateFormatter = (val: Date) => {
    const date = val.toISOString();
    const dateBits = date.split("T")[0].split("-");
    const newDate = dateBits[1] + "/" + dateBits[2] + "/" + dateBits[0];
    return newDate;
}

export function prevWeek(today:Date){
    var prevweek = new Date(today.getFullYear(), today.getMonth(), today.getDate()-7);
    return prevweek;
}

export const formatDateForGrid = (dateString : string) => {
    try {

        const date = `${dateString}`; // string cast in case of Date object

        if (date) {
            if (date.includes('/')) {
                return date;
            } else {
                const segs = date.split('T')[0].split('-');
                if (segs && segs.length >= 3) {
                    if (segs[0].length === 4) {
                        return `${segs[1]}/${segs[2]}/${segs[0]}`;
                    }
                    else {
                        return `${segs[0]}/${segs[1]}/${segs[2]}`;
                    }
                }
            }
        }
    } catch (e) {
        console.log(e);
    }
    return dateString;
}

export const comparatorForDate = (filterLocalDateAtMidnight: Date, cellValue: string) => {
    const dateAsString = cellValue;

    if (dateAsString == null) {
      return 0;
    }

    // In the example application, dates are stored as dd/mm/yyyy
    // We create a Date object for comparison against the filter date
    const dateParts = dateAsString.split("/");
    const year = Number(dateParts[2]);
    const month = Number(dateParts[1]) - 1;
    const day = Number(dateParts[0]);
    const cellDate = new Date(year, month, day);

    // Now that both parameters are Date objects, we can compare
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    } else if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  }

  export const filterColumnsObjCreator = (
    member: string,
    value: string | Date | number | string[] | boolean,
    dataTypeVal: string
  ) => {
    if (member && value && dataTypeVal) {
    
     // specific case for claim status
     if(member === "claimStatus" && dataTypeVal === "string[]") {
      return {
        member: member,
        value: value,
        dataType: dataTypeVal,
        operationType:"Equals"
      };
     }

      if (dataTypeVal === "string[]" || dataTypeVal === "int[]" || dataTypeVal === "bool"){
        return {
          member: member,
          value: value,
          dataType: dataTypeVal,
        };
      } else if(dataTypeVal === "number") {
        return {
          member: member,
          value: [value],
          dataType: "int",
        };
      } else {
        return {
          member: member,
          value: [value],
          dataType: dataTypeVal,
        };
      }
    }
  };

export const LandingHeader = styled.section`
  width: 100%;
  height: 100%;
  padding: 0.5em 0;
  margin: 0;
  border: none;
  margin-bottom: 0.5em;
  background-color: ${(props) => props.theme.backgroundColor};
`;

export const GridContainer = styled.div`
  height: 90%;
  width: 100%;
  padding: 0px;
`;

export interface ViewSchema {
  columnData: string;
  createdBy: string;
  createdDate: string;
  filterData: string;
  isDefault: boolean;
  isSystem: boolean;
  modifiedBy: string | null;
  modifiedDate: string | null;
  screenName: string;
  userGridViewID?: string;
  viewName: string;
}

export const currencyFormatter = (value: number | null | undefined,formatNegativeValues = false) => {
  if(value === undefined || value === null) return "";
  const formattedValue = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits:2
    }).format(value);
   return formatNegativeValues && value < 0 ? `(${formattedValue.slice(1)})` : formattedValue;  
};

export const percentageFormatter = (value: any) => {
  if(value === undefined) return "";
  return parseFloat(value.toFixed(2)) + "%";
};

export type ModalErrorMessagesType = {messages:string[], correlationID?:string}; 
export type ModalErrors = { errors: ModalErrorMessagesType ; ErrorModelOpen: boolean };

export interface ModalErrorsType {
  modalErrors:ModalErrors;
  setModalErrors: (v:ModalErrors) => void;
}

export const negativeValueColorFormatter = (value:number | null | undefined) => {
  if(value === undefined || value === null) return undefined;
  if (value < 0) {
    return { color: "#FF0000" }
  }
}

export const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 5px 5px;
  align-items: center;
  gap: 10px;
`;