import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ArticleIcon from "@mui/icons-material/Article";

import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../redux/store";
import { useSelector, useDispatch } from "react-redux";
import {
  LandingPageAppsType,
  OutputAllowedApps,
  UserAppsLookup,
  checkLandingPageTilesAccess,
  landingPageTilesSorter,
} from "./Components/admin/adminUtils";
import { SimpleBackdrop } from "@genrecp/g2clientportal-common";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  handleDocumetationTabsToggler,
  handleReportingTabsToggler,
  handleTopMenuandSideBarToggler,
  handleadminPortalTabsToggler,
} from "../redux/slices/commonSlice";

const metaData: LandingPageAppsType[] = [
  {
    title: "Loss Reporting",
    detail: `Our self-service Loss Run tool.
    It allows you to access loss information for individual policy numbers or claim numbers.`,
    icon: <RequestQuoteIcon />,
    to: "/lossReporting",
    name: "lossReporting",
    apptypeID: UserAppsLookup.LossReporting,
  },
  {
    title: "Electronic Broker Billing",
    detail: `This option provides our registered Brokers with electronic access
    to their monthly bills. Our Brokers can review their bills, add
    comments and indicate the items they currently intend to pay.
    Please note that at this time the Direct Billing business is not
    included in this electronic billing section.`,
    icon: <AccountBalanceIcon />,
    to: "/brokerBilling",
    name: "brokerBilling",
    apptypeID: UserAppsLookup.BrokerBilling,
  },
  {
    title: "Underwriting Guide",
    detail: `A comprehensive guide for brokers that includes sections entitled Property, Casualty, Property Special Handling, Casuality Special Handling, Inland Marine, Operations and Procedures, and Applications and Forms.`,
    icon: <ArticleIcon />,
    to: "/Underwriting-Guide",
    apptypeID: UserAppsLookup.UnderwritingGuide,
  },
  {
    title: "Supplemental Applications",
    detail: `GenStar provides fillable supplemental applications that can be filled right on your screen. you can complete it or e-mail it to your retailer to complete it. The form allows for the data to be saved.`,
    icon: <ArticleIcon />,
    to: "/Supplemental-Applications",
    apptypeID: UserAppsLookup.SupplementalApplications,
  },
  {
    title: "Applications",
    detail: `GenStar provides web-enabled application forms from various departments`,
    icon: <ArticleIcon />,
    to: "/Applications",
    apptypeID: UserAppsLookup.Applications,
  },
  {
    title: "Oracle and Docucorp Libraries",
    detail: `This option gives access to Oracle and Docucorp Libraries`,
    icon: <ArticleIcon />,
    to: "/Oracle-and-Docucorp-Libraries",
    apptypeID: UserAppsLookup.OracleDocucorpLibraries,
  },
  {
    title: "Experience Report",
    detail: `Report that presents Premium with loss data, by client location, as of the prior months close of business.`,
    icon: <RequestQuoteIcon />,
    to: "/experienceReport",
    apptypeID: UserAppsLookup.DelegatedExperienceReport,
  },
  {
    title: "Incurred Losses",
    detail: `Report that presents Loss data aggregation by client location as of prior business day.`,
    icon: <RequestQuoteIcon />,
    to: "/incurredLosses",
    apptypeID: UserAppsLookup.DelegatedIncurredLossReport,
  },
];

const grouped = [
  {
    title: "Admin Portal",
    detail: `Internal user tool for granting, updating and revoking access to the applications
   hosted here to both internal and external users. `,
    icon: <SettingsIcon />,
    to: "",
    apptypeID: 1,
  },
  {
    title: "Reporting Applications",
    detail: `Internal user tool for analyzing site usage and access. `,
    icon: <ArticleIcon />,
    apptypeID: 1,
    to: "",
  },
];

type Props = { allowedApps: OutputAllowedApps };

function ClientPortal({ allowedApps }: Props) {
  const navigate = useNavigate();
  const $dispatch = useDispatch<AppDispatch>();
  const isExternal = useSelector(
    (state: RootState) => state?.auth?.currentUser?.isExternal
  );
  const isExternalUser = isExternal && JSON.parse(isExternal.toLowerCase());

  const appRolePermissions = useSelector(
    (state: RootState) => state.auth.currentUser?.appRolePermissions
  );
  const [apps, setApps] = React.useState<any>([]);
  const [loader, setLoader] = React.useState<boolean>(false);

  function getPermittedApps() {
    setLoader(true);

    const finalTabs = checkLandingPageTilesAccess(
      metaData,
      isExternalUser,
      appRolePermissions
    );

    if (allowedApps?.["REPORTING"] && allowedApps?.["REPORTING"].length > 0) {
      grouped[1].to = allowedApps["REPORTING"][0].to;
      grouped[1].apptypeID = allowedApps["REPORTING"][0].apptypeID;
      finalTabs.push(grouped[1]);
    }
    if (allowedApps?.["ADMIN"] && allowedApps?.["ADMIN"].length > 0) {
      grouped[0].to = allowedApps["ADMIN"][0].to;
      grouped[0].apptypeID = allowedApps["ADMIN"][0].apptypeID;
      finalTabs.push(grouped[0]);
    }

    setApps(finalTabs.sort(landingPageTilesSorter));
    setLoader(false);
  }

  const handleNavigation = (item: any) => {
    $dispatch(handleTopMenuandSideBarToggler(false));
    switch (item.title) {
      case "Admin Portal":
        $dispatch(handleadminPortalTabsToggler(true));
        $dispatch(handleReportingTabsToggler(false));
        $dispatch(handleDocumetationTabsToggler(false));
        navigate(item.to);
        break;
      case "Reporting Applications":
        $dispatch(handleadminPortalTabsToggler(false));
        $dispatch(handleReportingTabsToggler(true));
        $dispatch(handleDocumetationTabsToggler(false));
        navigate(item.to);
        break;
      case "Underwriting Guide":
      case "Supplemental Applications":
      case "Applications":
      case "Oracle/Docucorp Libraries":
        $dispatch(handleadminPortalTabsToggler(false));
        $dispatch(handleReportingTabsToggler(false));
        $dispatch(handleDocumetationTabsToggler(true));
        navigate(item.to);
        break;
      default:
        $dispatch(handleadminPortalTabsToggler(false));
        $dispatch(handleReportingTabsToggler(false));
        $dispatch(handleDocumetationTabsToggler(false));
        navigate(item.to);
        break;
    }
  };

  React.useEffect(() => {
    if (!appRolePermissions) return;
    else getPermittedApps();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appRolePermissions]);

  return (
    <Grid container>
      <SimpleBackdrop open={loader} />
      <Grid
        display={"flex"}
        flexDirection={{ md: "row", sm: "column", xs: "column" }}
        mb={2}
        gap={2}
        item
      >
        <Grid
          md={2}
          sm={12}
          xs={12}
          item
          display={"flex"}
          justifyContent={{ md: "flex-start" }}
          alignItems={"flex-start"}
        >
          <Box width={{ md: "70%", sm: "30%" }} sx={{ position: "relative" }}>
            <img
              src={"/GenStar-R.png"}
              alt="general star logo"
              style={{ width: "100%", objectFit: "contain" }}
            />
            <Typography
              component="h1"
              variant="h5"
              align="right"
              fontStyle={"italic"}
              sx={{
                cursor: "pointer",
                position: "absolute",
                right: 0,
                top: "70px",
              }}
            >
              Client Portal
            </Typography>
          </Box>
        </Grid>
        <Grid
          md={10}
          sm={12}
          xs={12}
          item
          display={"flex"}
          flexDirection={"column"}
          gap={1}
          pt={1}
        >
          <Typography variant="headlineMedium">
            Welcome to the GenStar Client Services Portal.
          </Typography>
          <Typography variant="bodyMedium">
            The purpose of this site is to offer you, our clients, access to
            certain information on some of the business for which you have
            transacted with GenStar.
          </Typography>
        </Grid>
      </Grid>
      <Grid
        md={12}
        sm={12}
        xs={12}
        lg={12}
        display={"flex"}
        justifyContent={"center"}
        mb={2}
        item
      >
        <Typography variant="displaySmall">
          CLIENT SERVICE APPLICATIONS
        </Typography>
      </Grid>
      <Grid
        sx={{
          display: "grid",
          gridTemplateColumns: {
            md: "1fr 1fr 1fr",
            sm: "1fr 1fr",
          },
          gap: 1,
        }}
        item
      >
        {apps.map((item: any, idx: any) => {
          return (
            <Card sx={{ minWidth: 275, ":hover": { boxShadow: 10 } }} key={idx}>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                {item.icon}
                <Typography
                  variant="h5"
                  component="div"
                  mb={2}
                  sx={{ cursor: "pointer", ":hover": { color: "blue" } }}
                  onClick={() => handleNavigation(item)}
                >
                  {item.title}
                </Typography>
                <Typography variant="body2" textAlign={"center"}>
                  {item.detail}
                </Typography>
              </CardContent>
            </Card>
          );
        })}
      </Grid>
    </Grid>
  );
}

export default ClientPortal;
