import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridClasses } from "@genre/g2common-theme";
import React from "react";
import { ColDef } from "ag-grid-community";
import { DelegatedProps } from "../utils";
import { currencyFormatter, negativeValueColorFormatter } from "@genrecp/g2clientportal-common";
import Grow from "@mui/material/Grow";
import {
  CellClassParams,
} from "ag-grid-community";

function LossesByClassCodeGrid({ request, dispatch }: DelegatedProps) {
  const defaultColDef: ColDef = {
    cellClass: "cell-wrap-text",
    cellStyle: { whiteSpace: "normal" },
    sortable: true,
    resizable: true,
    filter: true,
    floatingFilter: true,
    flex:1
  };

  const colDefs: ColDef[] = [
    {
      colId: "classDescription",
      field: "classDescription",
      headerName: "Class Description",
      filter: "agTextColumnFilter",
      flex:1.5,
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "Premium Written",
      field: "premiumWriteAmount",
      colId: "premiumWriteAmount",
      filter: "agNumberColumnFilter",
      cellStyle: (params:CellClassParams) => negativeValueColorFormatter(params.data?.premiumWriteAmount),
      valueFormatter: function (params: any) {
        return `${currencyFormatter(params?.data?.premiumWriteAmount, true)}`;
      },
      filterParams: {
        filterOptions: ["equals"],
        suppressAndOrCondition: true,
      },
    },
    {
      colId: "premiumEarnedAmount",
      field: "premiumEarnedAmount",
      headerName: "Premium Earned",
      filter: "agNumberColumnFilter",
      cellStyle: (params:CellClassParams) => negativeValueColorFormatter(params.data?.premiumEarnedAmount),
      valueFormatter: function (params: any) {
        return `${currencyFormatter(params?.data?.premiumEarnedAmount, true)}`;
      },
      filterParams: {
        filterOptions: ["equals"],
        suppressAndOrCondition: true,
      },
    },
    {
      colId: "incurredAmount",
      headerName: "Incurred Amount",
      field: "incurredAmount",
      filter: "agNumberColumnFilter",
      cellStyle: (params:CellClassParams) => negativeValueColorFormatter(params.data?.incurredAmount),
      valueFormatter: function (params: any) {
        return `${currencyFormatter(params?.data?.incurredAmount, true)}`;
      },
      filterParams: {
        filterOptions: ["equals"],
        suppressAndOrCondition: true,
      },
    },
    {
      colId: "lossRatio",
      field: "lossRatio",
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: ["equals"],
        suppressAndOrCondition: true,
      },
      valueFormatter: (params: any) => {
        const { lossRatio } = params.data;
        return lossRatio + "%";
      },
    },
  ];

  return (
    <Grow in={true} style={{ transitionDelay: "100ms" }}>
      <div
        className={`ag-theme-alpine ${AgGridClasses["ag-theme-alpine"]}`}
        style={{ width: "100%", height: window.innerHeight - 310 }}
      >
        <AgGridReact
          rowHeight={35}
          defaultColDef={defaultColDef}
          columnDefs={colDefs}
          rowData={request.premiumLossesByClassCode}
          pagination={true}
          paginationPageSize={10}
          animateRows={true}
          headerHeight={40}
        ></AgGridReact>
      </div>
    </Grow>
  );
}

export default LossesByClassCodeGrid;
