import * as React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SettingsIcon from "@mui/icons-material/Settings";
import { SignInButton } from "../../../App/signin/SignInButton";
import { SignOutButton } from "../../../App/signin/SignOutButton";
import { useDispatch, useSelector } from "react-redux";
import {
  RootState,
  AppDispatch,
} from "@genrecp/g2clientportal-client-portal/src/redux/store";
import { AllowedApps, OutputAllowedApps } from "../admin/adminUtils";
import {
  handleTopMenuandSideBarToggler,
  handleadminPortalTabsToggler,
  handleDocumetationTabsToggler,
  handleReportingTabsToggler,
  handleDelegatedTabsToggler,
} from "../../../redux/slices/commonSlice";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import AssessmentIcon from "@mui/icons-material/Assessment";
import CommonListItem from "./CommonListItem";
import CommonListButton from "./CommonListButton";
import { useNavigate } from "react-router-dom";
import Collapse from "@mui/material/Collapse";

const drawerWidth = 300;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  marginTop: "0 !important",
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginTop: "0 !important",
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  // necessary for content to be below app bar
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

interface Props {
  children: JSX.Element;
  allowedApps: OutputAllowedApps;
}

export const HeaderWithSideBar: React.FC<Props> = ({
  children,
  allowedApps,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.msalUser
  );
  const $dispatch = useDispatch<AppDispatch>();

  const initialValueMenuToggle = useSelector(
    (state: RootState) => state.common.topMenuToggler
  );
  const adminPortalTabsToggler = useSelector(
    (state: RootState) => state.common.adminPortalTabsToggler
  );
  const documentationTabsToggler = useSelector(
    (state: RootState) => state.common.documentationTabsToggler
  );
  const reportingTabsToggler = useSelector(
    (state: RootState) => state.common.reportingTabsToggler
  );
  const delegatedTabsToggler = useSelector(
    (state: RootState) => state.common.delegatedTabsToggler
  );

  const handleDrawerOpen = () => {
    $dispatch(handleTopMenuandSideBarToggler(true));
  };

  const handleDrawerClose = () => {
    $dispatch(handleTopMenuandSideBarToggler(false));
  };

  const handleAdminToggle = () => {
    $dispatch(handleadminPortalTabsToggler(!adminPortalTabsToggler));
  };

  const handleDocumentationToggle = () => {
    $dispatch(handleDocumetationTabsToggler(!documentationTabsToggler));
  };

  const handleReportingToggle = () => {
    $dispatch(handleReportingTabsToggler(!reportingTabsToggler));
  };

  const handleDelegatedToggle = () => {
    $dispatch(handleDelegatedTabsToggler(!delegatedTabsToggler));
  };

  const handleNavigation = () => {
    $dispatch(handleadminPortalTabsToggler(false));
    $dispatch(handleReportingTabsToggler(false));
    $dispatch(handleDocumetationTabsToggler(false));
    handleDrawerClose();
    navigate("/clientPortal");
  };

  const flatMappedAllowedApps = Object.values(allowedApps).flat();
  const togglerStateMap = {
    ADMIN: !!adminPortalTabsToggler!!,
    DOCUMENTATION: !!documentationTabsToggler,
    REPORTING: !!reportingTabsToggler,
    DELEGATED: !!delegatedTabsToggler,
  };
  const togglerIconMap = {
    ADMIN: <SettingsIcon />,
    DOCUMENTATION: <TextSnippetIcon />,
    REPORTING: <AssessmentIcon />,
    DELEGATED: <AssessmentIcon />,
  };
  const togglerStateFuncMap = {
    ADMIN: handleAdminToggle,
    DOCUMENTATION: handleDocumentationToggle,
    REPORTING: handleReportingToggle,
    DELEGATED: handleDelegatedToggle,
  };

  const landingPageTilesSorter = (
    a: AllowedApps,
    b: AllowedApps
  ) => {
    let fa = a.primary.replace(/ +/g, "").toLowerCase(),
      fb = b.primary.replace(/ +/g, "").toLowerCase();
  
    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={initialValueMenuToggle}>
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(initialValueMenuToggle && { display: "none" }),
            }}
          >
            <ChevronRightIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            <span
              onClick={() => handleNavigation()}
              style={{ cursor: "pointer" }}
            >
              Client Portal
            </span>
          </Typography>
          {isAuthenticated ? <SignOutButton /> : <SignInButton />}
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={initialValueMenuToggle}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <List dense={true}>
          {!!flatMappedAllowedApps.length && !flatMappedAllowedApps.some(item => !item)
            ? Object.entries(allowedApps).map(([keys, value],idx) => {
                switch (keys) {
                  case "ADMIN":
                  case "DOCUMENTATION":
                  case "DELEGATED":
                  case "REPORTING":
                    return (
                      <React.Fragment key={idx}>
                        <CommonListButton
                          tabIcon={togglerIconMap[keys]}
                          tabToggler={togglerStateMap[keys]!}
                          initialValueMenuToggle={initialValueMenuToggle}
                          togglerFunc={togglerStateFuncMap[keys]!}
                          tabName={allowedApps[keys]![0].groupTabName}
                        />
                        <Collapse
                          in={togglerStateMap[keys]}
                          timeout="auto"
                          unmountOnExit
                        >
                            {allowedApps[keys]!.sort(landingPageTilesSorter).map((item, idx) => {
                              return (
                                <CommonListItem
                                  item={item}
                                  initialValueMenuToggle={
                                    initialValueMenuToggle
                                  }
                                  key={idx}
                                />
                              );
                            })}
                        </Collapse>
                      </React.Fragment>
                    );
                  case "BROKERBILLING":
                  case "DATASYNC":
                  case "LOSSREPORTING":
                    return allowedApps[keys]!.map((item, idx) => (
                      <CommonListItem
                        item={item}
                        initialValueMenuToggle={initialValueMenuToggle}
                        key={idx}
                      />
                    ));
                  default:
                    return null;
                }
              })
            : null}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, px: 1, pt: 8 }}>
        {children}
      </Box>
    </Box>
  );
};
