import * as React from "react";
import { Divider } from "@mui/material";
import styled from "styled-components";
import { MuiCustomDrawer } from "@genre/g2common-theme";

const ScrollPanel = styled.div`
  height: calc(100% - 38px);
  width: 100%;
  padding: 0 0 50px 0;
  margin: 0;
  border: 0;
  overflow-x: hidden;
  overflow-y: auto;
`;

const InputPanel = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-content: flex-start;
`;

const Container = styled.div`
  padding: 20px;
`;

interface Props {
  title: string;
  children: JSX.Element;
  drawerWidth: number;
  setOpen: (v: boolean) => void;
  open: boolean;
  purge?: () => void;
}

const CustomDrawer: React.FC<Props> = ({
  title,
  children,
  drawerWidth,
  setOpen,
  open,
  purge,
}: Props) => {
  const closeDrawer = () => {
    setOpen(false);
    purge && typeof purge === "function" && purge();
  };

  return (
    <MuiCustomDrawer
      open={open}
      anchor={"right"}
      drawerWidth={drawerWidth}
      title={title}
      onClose={closeDrawer}
      justifyContent="flex-start"
    >
      <>
        <ScrollPanel>
          <InputPanel>
            <Container>{children}</Container>
          </InputPanel>
        </ScrollPanel>
        <Divider />
      </>
    </MuiCustomDrawer>
  );
};

export default CustomDrawer;
