import React from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { ColDef, GridReadyEvent } from "ag-grid-community";
import {
  DeleteChildRenderer, ModalErrors, handleErrorResponse,
} from "@genrecp/g2clientportal-common";
import { useSelector, useDispatch } from "react-redux";
import { RootState ,AppDispatch } from "@genrecp/g2clientportal-client-portal/src/redux/store";
import { brokerBillingAxios } from "@genrecp/g2clientportal-client-portal/src/services/axios";
import { useRibbonSnackbar } from "@genrecp/g2clientportal-common";
import {Request} from "./AccountGroup";
import { handleGenbillState, handleBillState } from "@genrecp/g2clientportal-client-portal/src/redux/slices/genBillSlice";
import {DetailSpan} from "../../genbillUtils"
import {AgGridClasses} from "@genre/g2common-theme";
let gridApi: any;

interface Props {
    request:Request;
    setRequest:(v:Request) => void;
    setModalErrors:(v:ModalErrors) => void;
    modalErrors:ModalErrors;
}   

function SubAccountGroup({request, setRequest,setModalErrors,modalErrors} : Props) {

  const { refreshToggler } = request;    
  const { enqueueSnackbar } = useRibbonSnackbar();
  const $dispatch = useDispatch<AppDispatch>();
  const bill = useSelector((state:RootState) => state.genbill.bill);

  const onDeleteRowSelected = (params: any) => {
    setRequest({...request, apiLoading:true});
    brokerBillingAxios
      .delete(`/AccountingGroup/Delete/${params.data.accountingGroupID}`)
      .then((res: any) => {
        setRequest({...request,refreshToggler:!request.refreshToggler, apiLoading:false});
        enqueueSnackbar(`Success! Accounting Group deleted successfully.`, {
          variant: "success",
          autoHideDuration:3000
        });
      })
      .catch((e: any) => {
        setRequest({...request, apiLoading:false});
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  };

  const onEditClicked = (params: any) => {
    
    $dispatch(
      handleGenbillState({
        type: "HANDLE_GENBILL_NAV",
        payload: { selectedMenu: "ACCOUNTGROUPDETAIL" },
      })
    );

    $dispatch(
      handleBillState({
        payload: { ...bill, accountGroup: {accountingGroupID: params.data.accountingGroupID} },
      })
    );

  };

  const ServerSideDatasource = () => {
    return {
      getRows: async function (params: any) {
          brokerBillingAxios
            .get("/AccountingGroup/Get/"+bill.billID)
            .then((res: any) => {
              setRequest({...request, apiLoading:false});
              var totalRows = -1;
              if (res.data !== null && res.data !== undefined) {
                if (res.data.length < 10) {
                  totalRows = params.request.startRow + res.data.length;
                }
                params.successCallback(res.data, totalRows);
              } else {
                params.successCallback([], totalRows);
              }
            })
            .catch((e: any) => {
              console.log(e.message);
              const modalErrorMessages = handleErrorResponse(e);
              setModalErrors({
                ...modalErrors,
                errors: modalErrorMessages,
                ErrorModelOpen: true,
              });
            });
      },
    };
  };

  const defaultColDef: ColDef = {
    cellClass: "cell-wrap-text",
    cellStyle: { whiteSpace: "normal" },
    sortable: true,
    resizable: true,
  };

  const colDefs: ColDef[] = [
    {
      field: "title",
      colId: "title",
      headerName: "Title",
      flex: 1,
    },
    {
      headerName: "",
      onCellClicked: onEditClicked,
      cellRenderer: () => {
        return (<DetailSpan>Edit</DetailSpan>);
      },
      flex: .1,
      width: 44,
    },
    // {
    //   headerName: "",
    //   onCellClicked: onRowEditSelected,
    //   cellRenderer: "EditChildRenderer",
    //   flex: 1,
    //   width: 44,
    // },
    {
      headerName: "",
      onCellClicked: onDeleteRowSelected,
      cellRenderer: "DeleteChildRenderer",
      flex: .1,
      width: 8,
    },
  ];

  const frameworkComponents = {
    DeleteChildRenderer: DeleteChildRenderer,
    // EditChildRenderer: EditChildRenderer,
  };
  const onGridReady = (params: GridReadyEvent) => {
    gridApi = params.api;
    var datasource = ServerSideDatasource();
    gridApi.setServerSideDatasource(datasource);
  };

  const refreshGrid = (gridApi:any) => {
    if(gridApi){
        var datasource = ServerSideDatasource();
        gridApi.setServerSideDatasource(datasource);
    }
}

  React.useEffect(() => {
    refreshGrid(gridApi);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshToggler]);
  
  React.useEffect(() => {
    return () => {
      gridApi && gridApi.destroy();
      gridApi = null
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ width: "100%", height: 400 }} className= {`ag-theme-alpine ${AgGridClasses['ag-theme-alpine']}`}>
      <AgGridReact
        rowHeight={35}
        defaultColDef={defaultColDef}
        columnDefs={colDefs}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={10}
        rowModelType={"serverSide"}
        suppressServerSideInfiniteScroll={false}
        cacheBlockSize={10}
        animateRows={true}
        components={frameworkComponents}
      ></AgGridReact>
    </div>
  );
}

export default SubAccountGroup;
