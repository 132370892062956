import { useState } from "react";
import React from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { RootState } from "@genrecp/g2clientportal-client-portal/src/redux/store";
import { useSelector } from "react-redux";
import {
  CellValueChangedEvent,
  ColDef,
  SelectionChangedEvent,
} from "ag-grid-community";
import { ConfirmationDialog, SelectList, showInvalidSnackbar, useConfirm, useRibbonSnackbar } from "@genrecp/g2clientportal-common";
import { SimpleBackdrop } from "@genrecp/g2clientportal-common";
import { brokerBillingAxios } from "@genrecp/g2clientportal-client-portal/src/services/axios";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Button } from "@genrecp/g2clientportal-common";
import { Stack, MenuItem, Grid } from "@mui/material";
import DetailCellRenderer from "./DetailCellRenderer";
import {AgGridClasses} from "@genre/g2common-theme";
import { PermissionLevelObject, StyledSpan, permissionLevelArray } from "../../genbillUtils";
var columnApi: any;
interface UserUpdateObject {
  billAccessID: string;
  billID: string;
  userID: string;
  permissionLevelID: number;
  createdDate: string;
  createdBy: string;
}

function UserAccessGrid() {
  const billID = useSelector((state: RootState) => state.genbill.bill.billID);
  const createdBy = useSelector((state: RootState) => state?.auth?.currentUser);
  const [apiLoading, setApiLoading] = React.useState<boolean>(false);
  const [deleteRowButton, setDeleteRowButton] = React.useState<boolean>(false);
  const [selectedRowsData, setSelectedRowsData] = React.useState<string[]>([]);
  const clientLocationID = useSelector(
    (state: RootState) => state?.genbill?.bill?.userAccess.clientLocationID
  );
  const {isConfirmed} = useConfirm();
  const { enqueueSnackbar } = useRibbonSnackbar();
  const [rowData, setRowData] = useState<any[]>();


  const [showPostButton, setShowPostButton] = React.useState<boolean>(false);

  function findName(v: number) {
    const found = permissionLevelArray.find(
      (item: PermissionLevelObject) => item.value === v
    );
    return (
      (
        <StyledSpan>
          {" "}
          <span> {found?.name} </span>{" "}
          <KeyboardArrowDownIcon style={{ position: "relative", top: "6px" }} />{" "}
        </StyledSpan>
      ) || ""
    );
  }

  const autoGroupColumnDef = React.useMemo<ColDef>(() => {
    return {
      minWidth: 200,
      filter: "agTextColumnFilter",
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        checkbox:  (params: any) => {
          return !params?.data;
        },
      },
      sort: "asc",
    };
  }, []);

  const [columnDefs] = useState<ColDef[]>([
    {
      field: "status",
      colId: "status",
      hide: true,
      rowGroup: true,
    },
    {
      field: "firstName",
      colId: "firstName",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
      checkboxSelection: (params: any) => {
        return params?.data ? true : false;
      },
    },
    {
      field: "lastName",
      colId: "lastName",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
    },
    {
      field: "userLoginID",
      colId: "userLoginID",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
    },
    {
      field: "emailAddress",
      colId: "emailAddress",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
    },
    {
      field: "permissionLevelID",
      colId: "permissionLevelID",
      headerName: "Permissions",
      // enables editing
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: ["Admin", "Contributor", "Viewer"],
      },
      cellEditorPopup: true,
      valueGetter: (params: any) => {
        if (params.data && params.data.permissionLevelID === 0) {
          return "";
        }
      },
      cellRenderer: (params: any) => {
        return params.data && params.data.status === "Selected" ? (
          findName(params.data.permissionLevelID)
        ) : params.data && params.data.status === "Unselected" ? (
          <StyledSpan>
            Select Permissions
            <KeyboardArrowDownIcon style={{ position: "relative", top: "6px" }} />
          </StyledSpan>
        ) : (
          ""
        );
      },
      filter: false,
    },
  ]);

  // React.useEffect(()=>{
  //   if(columnApi && (deleteRowButton || showPostButton)) {
  //     if(columnApi?.getColumn("permissionLevelID")?.getColDef()) columnApi.getColumn("permissionLevelID").getColDef().editable= false;

  //   } else {
  //     if(columnApi?.getColumn("permissionLevelID")?.getColDef()) columnApi.getColumn("permissionLevelID").getColDef().editable = true;
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[columnApi,deleteRowButton,showPostButton]);


  const defaultColDef: ColDef = {
    cellClass: "cell-wrap-text",
    cellStyle: { whiteSpace: "normal" },
    flex: 1,
    sortable: true,
    resizable: true,
    filter: true,
    floatingFilter: true,
    enableRowGroup: true,
  };

  const getData = () => {
    setSelectedRowsData([]);
    setShowPostButton(false);
    setDeleteRowButton(false);
      setApiLoading(true);
      var url = "";
      url = clientLocationID
        ? `/Bills/GetUsersByAssignedLocations/${billID}`
        : `/Bills/GetUsersByAssignedGroup/${billID}`;
      brokerBillingAxios
        .get(url)
        .then((res: any) => {
          setRowData(res.data);
          setApiLoading(false);
        })
        .catch((error: any) => {
          setApiLoading(false);
          console.log(error);
        });
  };

  const postData = (payload:any[]) => {
    brokerBillingAxios
        .post("/BillAccesses/Create", payload)
        .then((res: any) => {
          if (res.status === 200) {
            enqueueSnackbar(`Success! User Updated Successfully.`, {
              variant: "success",
              autoHideDuration:3000
            });
            setSelectedRowsData([]);
            setShowPostButton(false);
            getData();
          }
        })
        .catch((e: any) => {
          console.log(e);
          setApiLoading(false);
          showInvalidSnackbar(e,enqueueSnackbar);
        });
  }

  const onGridReady = (params: any) => {
    params?.api?.sizeColumnsToFit();
    columnApi = params.columnApi;
    getData();
  };

  const onCellValueChanged = React.useCallback(
    (params: CellValueChangedEvent) => {
      const { userID, permissionLevelID } = params.data;

      if (permissionLevelID === "") {
        enqueueSnackbar(`Error! Permission cannot be empty.`, {
          variant: "error",
        });
        return;
      }

      const permission = permissionLevelArray.find(
        (item: any) => item.name === permissionLevelID
      );

      if (!createdBy) return;

      const payload: Partial<UserUpdateObject> = {
        billID,
        userID,
        permissionLevelID: permission?.value,
        createdDate: new Date().toISOString(),
        createdBy: createdBy.id,
      };
      setApiLoading(true);
      postData([payload]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onSelectionChanged = React.useCallback(
    (event: SelectionChangedEvent) => {
      let rows = event.api.getSelectedNodes();
      let selectedRows = rows.map((item: any) => item.data);

      if (
        selectedRows.every((item: any) => item.status === "Selected") &&
        selectedRows.length > 0
      ) {
        setDeleteRowButton(true);
        setShowPostButton(false);
        const payload = selectedRows.map((item: any) => item.billAccessID);
        setSelectedRowsData(payload);
      } else if (
        selectedRows.every((item: any) => item.status === "Unselected") &&
        selectedRows.length > 0
      ) {
        setShowPostButton(true);
        setDeleteRowButton(false);
        const payload = selectedRows.map((item: any) => item.userID);
        setSelectedRowsData(payload);
      } else {
        setDeleteRowButton(false);
        setSelectedRowsData([]);
        setShowPostButton(false);
        return;
      }
    },
    []
  );

  const submitUser = async () => {
    if (selectedRowsData.length === 0) {
      return;
    }
    if(!createdBy) return; 
    
    const copiedUserIDs = [...selectedRowsData];
    var dataArray:any[] = [];
    var obj = {} as any;
    copiedUserIDs.forEach((item:any) => {
      obj.billID = billID;
      obj.userID = item;
      obj.permissionLevelID = 3;
      obj.createdDate = new Date().toISOString();
      obj.createdBy = createdBy.id;
      dataArray.push(obj);
      obj={};
    });
    const flag = await isConfirmed(`You are going to assign viewer permission to all of the selected users. Do you wish to proceed?`);
    if(flag) {
      setApiLoading(true);
      postData(dataArray);
    } else {
      dataArray=[];
    }
  };

  const removePermssions = () => {
    if (selectedRowsData.length === 0) {
      return;
    }

    setApiLoading(true);
    brokerBillingAxios
      .delete(`/BillAccesses/Delete`, {
        data: selectedRowsData,
      })
      .then((res: any) => {
        setDeleteRowButton(false);
        enqueueSnackbar(`Action was successfully performed!`, {
          variant: "success",
          autoHideDuration:3000
        });
        getData();
      })
      .catch((e: any) => {
        setApiLoading(false);
        showInvalidSnackbar(e,enqueueSnackbar);
        console.log(e);
      });
  };

  const detailCellRenderer = React.useMemo(() => {
    return DetailCellRenderer;
  }, []);

  return (
    <Grid mt={1}>
      <SimpleBackdrop open={apiLoading} />
      <ConfirmationDialog />
      {deleteRowButton ? (
        <Button
          variant="contained"
          onClick={() => removePermssions()}
          id="removePermssions"
          sx={{ marginBottom: "12px" }}
        >
          Remove Permissions
        </Button>
      ) : null}
      {showPostButton ? (
        <Grid md={2} my={1}>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <SelectList
              label="Permissions"
              name="permissions"
              id="permissions"
              test-id="permissions"
              value={3}
            >
              <MenuItem value={3}>Viewer</MenuItem>
            </SelectList>
            <Button
              variant="contained"
              onClick={() => submitUser()}
              id="submit"
            >
              Submit
            </Button>
          </Stack>
        </Grid>
      ) : null}
      <div
        className= {`ag-theme-alpine ${AgGridClasses['ag-theme-alpine']}`}
        style={{ width: "100%", height: window.innerHeight - 270 }}
      >
        <AgGridReact
          rowHeight={35}
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          rowData={rowData}
          defaultColDef={defaultColDef}
          pagination={true}
          paginationPageSize={10}
          animateRows={true}
          groupDisplayType={"multipleColumns"}
          singleClickEdit={true}
          onCellValueChanged={onCellValueChanged}
          autoGroupColumnDef={autoGroupColumnDef}
          stopEditingWhenCellsLoseFocus={true}
          groupSelectsChildren={true}
          suppressRowClickSelection={true}
          suppressAggFuncInHeader={true}
          rowSelection={"multiple"}
          onSelectionChanged={onSelectionChanged}
          masterDetail={true}
          detailRowAutoHeight={true}
          detailCellRenderer={detailCellRenderer}
        ></AgGridReact>
      </div>
    </Grid>
  );
}

export default UserAccessGrid;
