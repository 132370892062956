import React from "react";
import { IncurredLossesProps } from "./utils";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridClasses } from "@genre/g2common-theme";
import { ColDef } from "ag-grid-community";
import {
  currencyFormatter,
} from "@genrecp/g2clientportal-common";

function IncurredLossesReportGrid({ request, dispatch }: IncurredLossesProps) {

  const defaultColDef: ColDef = {
    cellClass: "cell-wrap-text",
    cellStyle: { whiteSpace: "normal" },
    sortable: true,
    resizable: true,
    filter: true,
    floatingFilter: true,
    flex:1
  };

  const colDefs: ColDef[] = [
    {
      colId: "causeLossCode",
      field: "causeLossCode",
      headerName: "Cause Loss Code",
      flex: .5,
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: ["equals"],
        suppressAndOrCondition: true,
      },
      hide:true
    },
    {
      headerName: "Cause Loss Description",
      field: "causeLossDescription",
      colId: "causeLossDescription",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
      flex: 1
    },
    {
      colId: "sumIncurredAmount",
      field: "sumIncurredAmount",
      headerName: "Sum Incurred Amount",
      filter: "agNumberColumnFilter",
      flex: .5,
      valueFormatter: function (params: any) {
        return `${currencyFormatter(params?.data?.sumIncurredAmount, true)}`;
      },
      filterParams: {
        filterOptions: ["equals"],
        suppressAndOrCondition: true,
      },
    },
    {
      colId: "claimCount",
      headerName: "Claim Count",
      field: "claimCount",
      filter: "agNumberColumnFilter",
      flex: .5,
      filterParams: {
        filterOptions: ["equals"],
        suppressAndOrCondition: true,
      },
      // valueFormatter: function (params: any) {
      //   return `${currencyFormatter(params?.data?.incurredAmount, true)}`;
      // },
    },
  ];

  return (
    <div
      className={`ag-theme-alpine ${AgGridClasses["ag-theme-alpine"]}`}
      style={{ width: "100%", height: window.innerHeight - 210 }}
    >
      <AgGridReact
        rowHeight={35}
        defaultColDef={defaultColDef}
        columnDefs={colDefs}
        rowData={request.incurredLossesReport}
        pagination={true}
        paginationPageSize={10}
        animateRows={true}
        headerHeight={40}
      ></AgGridReact>
    </div>
  );
}

export default IncurredLossesReportGrid;
