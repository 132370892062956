import React from 'react'
import { Link,useLocation } from "react-router-dom";
import {ListItem,ListItemButton,ListItemText,ListItemIcon, Tooltip} from "@mui/material";
import { AppDispatch } from '../../../redux/store';
import { useDispatch } from 'react-redux';
import { handleTopMenuandSideBarToggler } from '../../../redux/slices/commonSlice';

const customLinkStyle = {
    textDecoration: "none",
    color: "rgba(0, 0, 0, 0.8",
  };

interface Props {
    item:any;
    initialValueMenuToggle:boolean|undefined;
}

const CommonListItem:React.FC<Props> = (props) => {
  const {item, initialValueMenuToggle} = props;
  const { pathname } = useLocation();
  const condition = item.name === "clientportal" || 
  item.name === "genBill" ||
  item.name === "lossReporting" ||
  item.name === "dataSync" ||
  item.name === "brokerBilling"; 
  
  const $dispatch = useDispatch<AppDispatch>();

  function handleAppClick() {
   if (!initialValueMenuToggle) return;
   $dispatch(handleTopMenuandSideBarToggler(false));
  }

  return (
  <Tooltip title={item.primary} placement="right">
    <Link to={item.to} style={customLinkStyle} onClick={handleAppClick}>
    <ListItem disablePadding sx={{ display: "block" }}>
      <ListItemButton
        sx={{
          justifyContent: initialValueMenuToggle
          ? "initial"
          : "center",
          px: 2.5,
          marginLeft: !condition && initialValueMenuToggle ? "20px" : "auto",
        }}
        selected={pathname === item.pathNameForTabs}
        >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: initialValueMenuToggle ? 2 : "auto",
            justifyContent: "center",
          }}
          >
          {item.icon}
        </ListItemIcon>
        <ListItemText
          primary={item.primary}
          sx={{ opacity: initialValueMenuToggle ? 1 : 0 }}
          />
      </ListItemButton>
    </ListItem>
  </Link>
</Tooltip>
  )
}

export default CommonListItem;