import React from "react";
import { Edit as EditIcon } from "@mui/icons-material";
import { IconButton } from "@mui/material";

export default function EditChildRenderer() {
  const handleReadByClick = () => {};

  React.useEffect(() => {}, []);
  return (
    <span>
      <IconButton
        name="Edit"
        title="Edit"
        onClick={handleReadByClick}
        style={{ position: "fixed", top: "0px" }}
      >
        <EditIcon color="primary" />
      </IconButton>
    </span>
  );
}
