import { useState } from "react";
import React from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { RootState } from "@genrecp/g2clientportal-client-portal/src/redux/store";
import { useSelector } from "react-redux";
import { ColDef, SelectionChangedEvent } from "ag-grid-community";
import {
  CustomSnackbar,
  useRibbonSnackbar,
  handleErrorResponse,
  showInvalidSnackbar,
  useConfirm,
  ConfirmationDialog,
  ModalErrors
} from "@genrecp/g2clientportal-common";
import { SimpleBackdrop } from "@genrecp/g2clientportal-common";
import { brokerBillingAxios } from "@genrecp/g2clientportal-client-portal/src/services/axios";
import { Button } from "@genrecp/g2clientportal-common";
import { Stack, Grid } from "@mui/material";
import { AgGridClasses } from "@genre/g2common-theme";
import {
  LandingHeader,
} from "../genbillUtils";
import {
  Radio,
  RadioGroup,
  Typography,
  FormControl,
  FormControlLabel,
} from "@mui/material";

function EmailList() {
  const billID = useSelector((state: RootState) => state.genbill.bill.billID);
  const createdBy = useSelector((state: RootState) => state?.auth?.currentUser);
  const [apiLoading, setApiLoading] = React.useState<boolean>(false);
  const [deleteRowButton, setDeleteRowButton] = React.useState<boolean>(false);
  const [selectedRowsData, setSelectedRowsData] = React.useState<any[]>([]);

  const { enqueueSnackbar } = useRibbonSnackbar();
  const [rowData, setRowData] = useState<any[]>();

  const [showPostButton, setShowPostButton] = React.useState<boolean>(false);
  const [autoSendBill, setAutoSendBill] = React.useState<boolean>(false);
  const [modalErrors, setModalErrors] = React.useState<ModalErrors>({
    errors: {messages:[]},
    ErrorModelOpen: false,
  });
  const {isConfirmed} = useConfirm();

  const getAutoSendFlag = React.useCallback(() => {
    setApiLoading(true);
    brokerBillingAxios
      .get(`/Bills/GetBillAutoSendFlag/${billID}`)
      .then((res: any) => {
        setApiLoading(false);
        const { data: autoSendFlagData } = res || {};
        setAutoSendBill(autoSendFlagData);
      })
      .catch((e) => {
        setApiLoading(false);
        console.log(e);
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateAutoSendFlag = React.useCallback((flag: boolean) => {
    setApiLoading(true);
    brokerBillingAxios
      .post(`/Bills/UpdateBillAutoSendFlag/${billID}/${flag}`)
      .then((res: any) => {
        setApiLoading(false);
        const { status } = res || {};
        if (status === 200) {
          enqueueSnackbar(`Action was successfully performed!`, {
            variant: "success",
            autoHideDuration:3000
          });
          setAutoSendBill(flag);
        }
      })
      .catch((e) => {
        setApiLoading(false);
        console.log(e);
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    getAutoSendFlag();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const autoGroupColumnDef = React.useMemo<ColDef>(() => {
    return {
      minWidth: 200,
      filter: "agTextColumnFilter",
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        checkbox:  (params: any) => {
          return !params?.data;
        },
      },
      sort: "asc",
    };
  }, []);

  const [columnDefs] = useState<ColDef[]>([
    {
      field: "status",
      colId: "status",
      hide: true,
      rowGroup: true,
    },
    {
      field: "firstName",
      colId: "firstName",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
      checkboxSelection: (params: any) => {
        return params.data ? true : false;
      },
    },
    {
      field: "lastName",
      colId: "lastName",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
    },
    {
      field: "emailAddress",
      colId: "emailAddress",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
    },
  ]);

  const defaultColDef: ColDef = {
    cellClass: "cell-wrap-text",
    cellStyle: { whiteSpace: "normal" },
    flex: 1,
    sortable: true,
    resizable: true,
    filter: true,
    floatingFilter: true,
    enableRowGroup: true,
  };

  const getData = () => {
    var url = `/BillEmailAlertRecipients/GetUserForBillAlert/${billID}`;

    brokerBillingAxios
      .get(url)
      .then((res: any) => {
        setApiLoading(false);
        const { data } = res || {};
        data ? setRowData(data) : setRowData([]);
      })
      .catch((e) => {
        setApiLoading(false);
        console.log(e);
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
        setRowData([]);
      });
  };

  const postData = () => {
    brokerBillingAxios
      .post("/BillEmailAlertRecipients/Create", selectedRowsData)
      .then((res: any) => {
        if (res.status === 200) {
          enqueueSnackbar(`Success! User Updated Successfully.`, {
            variant: "success",
            autoHideDuration:3000
          });
          setSelectedRowsData([]);
          setShowPostButton(false);
          getData();
        }
      })
      .catch((e: any) => {
        console.log(e);
        setApiLoading(false);
        showInvalidSnackbar(e,enqueueSnackbar);
      });
  };

  const onGridReady = (params: any) => {
    params?.api?.sizeColumnsToFit();
    getData();
  };

  const onSelectionChanged = React.useCallback(
    (event: SelectionChangedEvent) => {
      let rows = event.api.getSelectedNodes();
      let selectedRows = rows.map((item: any) => item.data);
      if (
        selectedRows.every((item: any) => item.status === "Selected") &&
        selectedRows.length > 0
      ) {
        setDeleteRowButton(true);
        setShowPostButton(false);
        const payload = selectedRows.map((item: any) => item.emailRecipientID);
        setSelectedRowsData(payload);
      } else if (
        selectedRows.every((item: any) => item.status === "Unselected") &&
        selectedRows.length > 0
      ) {
        setShowPostButton(true);
        setDeleteRowButton(false);
        const payload = selectedRows.map((item: any) => {
          return {
            userID: item.userID,
            billID: billID,
            createdDate: new Date().toISOString(),
            createdBy: createdBy && createdBy.id,
          };
        });
        setSelectedRowsData(payload);
      } else {
        setDeleteRowButton(false);
        setSelectedRowsData([]);
        setShowPostButton(false);
        return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const submitUser = async () => {
    if (selectedRowsData.length === 0) {
      return;
    }
    if (!createdBy) return;

    const flag = await isConfirmed(
      `You are going to assign the selected users to Bill's Email alert. Do you wish to proceed?`
    );
    if (flag) {
      setApiLoading(true);
      postData();
    }
  };

  const removeSelection = () => {
    if (selectedRowsData.length === 0) {
      return;
    }

    setApiLoading(true);
    brokerBillingAxios
      .delete(`/BillEmailAlertRecipients/Delete`, {
        data: selectedRowsData,
      })
      .then((res: any) => {
        setDeleteRowButton(false);
        enqueueSnackbar(`Action was successfully performed!`, {
          variant: "success",
          autoHideDuration:3000
        });
        getData();
      })
      .catch((e: any) => {
        setApiLoading(false);
        showInvalidSnackbar(e,enqueueSnackbar);
        console.log(e);
      });
  };

  return (
    <Grid>
      <SimpleBackdrop open={apiLoading} />
      <ConfirmationDialog />
      <CustomSnackbar request={modalErrors} setRequest={setModalErrors} />
      <Grid
        md={12}
        item
        display={"flex"}
        justifyContent={"start"}
        alignItems={"center"}
        gap={2}
      >
        <Typography align="left" fontWeight={"bold"}>
          Auto Send Bills
        </Typography>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
            value={autoSendBill}
            onChange={(e) => updateAutoSendFlag(JSON.parse(e.target.value))}
            sx={{ display: "flex", flexDirection: "row" }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </Grid>
      {deleteRowButton ? (
        <Button
          variant="contained"
          onClick={() => removeSelection()}
          id="removeSelection"
          sx={{ marginBottom: "12px" }}
        >
          Remove Email Alerts
        </Button>
      ) : null}
      {showPostButton ? (
        <Grid md={2} mb={2}>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Button
              variant="contained"
              onClick={() => submitUser()}
              id="submit"
              sx={{ marginBottom: "12px" }}
            >
              Assign Email Alerts
            </Button>
          </Stack>
        </Grid>
      ) : null}
      <LandingHeader>
        <div
          className={`ag-theme-alpine ${AgGridClasses["ag-theme-alpine"]}`}
          style={{ width: "100%", height: window.innerHeight - 350 }}
        >
          <AgGridReact
            columnDefs={columnDefs}
            rowHeight={35}
            onGridReady={onGridReady}
            rowData={rowData}
            defaultColDef={defaultColDef}
            pagination={true}
            paginationPageSize={10}
            animateRows={true}
            groupDisplayType={"multipleColumns"}
            singleClickEdit={true}
            autoGroupColumnDef={autoGroupColumnDef}
            stopEditingWhenCellsLoseFocus={true}
            groupSelectsChildren={true}
            suppressRowClickSelection={true}
            suppressAggFuncInHeader={true}
            rowSelection={"multiple"}
            onSelectionChanged={onSelectionChanged}
            masterDetail={true}
            detailRowAutoHeight={true}
          ></AgGridReact>
        </div>
      </LandingHeader>
    </Grid>
  );
}

export default EmailList;
