import React from "react";
import styled from "styled-components";
import {
  UserRequestObject,
  AppTypeRoles,
  Role,
  ApptypeTypeArray,
  UserDispatch,
} from "../userUtils";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { MuiStyledSwitch as Switch } from "@genre/g2common-theme";
import { Autocomplete } from "@mui/material";
import { TextInput } from "@genrecp/g2clientportal-common";

export const Container = styled.div`
  padding: 5px;
`;

export const InnerRoleSwitchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`;

interface Props {
  AppTypeRoles: AppTypeRoles[];
  request: UserRequestObject;
  // setRequest: (v: UserRequestObject) => void;
  setAppTypeRoles: (v: AppTypeRoles[]) => void;
  appTypeArray: ApptypeTypeArray[];
  appTypeLoadingSpecificCase: boolean;
  appTypeInputValue: string;
  setAppTypeInputValue: (v: string) => void;
  dispatch: UserDispatch
}

export const CustomDrawerForm: React.FC<Props> = ({
  AppTypeRoles,
  request,
  setAppTypeRoles,
  // setRequest,
  appTypeArray,
  appTypeLoadingSpecificCase,
  appTypeInputValue,
  setAppTypeInputValue,
  dispatch
}) => {
  const label = { inputProps: { "aria-label": "Switch demo" } };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    appTypeID: number
  ) => {
    const { name, checked } = event?.target;

    let newApproles: AppTypeRoles[] = [...AppTypeRoles];

    newApproles.forEach((item: any) => {
      item.roles.forEach((innerItem: any) => {
        if (innerItem.name === name && appTypeID === item.appTypeID) {
          innerItem["isAssignedToApp"] = checked;
        }
      });
    });

    setAppTypeRoles(newApproles);
  };

  return (
    <Container>
      <Grid container spacing={2} maxWidth="md">
        <Grid xs={12} sm={12} md={12} item>
          {!appTypeLoadingSpecificCase ? (
            <>
              <Autocomplete
                id="userAppTypes"
                test-id="userAppTypes"
                multiple
                limitTags={1}
                size="small"
                options={appTypeArray}
                disabled={
                  request.userClientLocations &&
                  request.userClientLocations.length === 0
                }
                value={request.userAppTypes}
                inputValue={appTypeInputValue}
                onInputChange={(event, newInputValue, reason) => {
                  if (reason === "clear") {
                    dispatch({userAppTypes: []})
                    // setRequest({ ...request, userAppTypes: [] });
                  } else {
                    setAppTypeInputValue(newInputValue);
                  }
                }}
                isOptionEqualToValue={(option, value) =>
                  option.appTypeText === value.appTypeText
                }
                getOptionLabel={(option: ApptypeTypeArray) =>
                  option.appTypeText!
                }
                onChange={(event, value) => {
                  if (value.length !== 0) {
                    dispatch({
                      userAppTypes: value,
                      openCustomDrawer: true,
                    })
                    // setRequest({
                    //   ...request,
                    //   userAppTypes: value,
                    //   openCustomDrawer: true,
                    // });
                  } else {
                    dispatch({userAppTypes: []})
                    // setRequest({ ...request, userAppTypes: [] });
                  }
                }}
                renderInput={(params) => (
                  <TextInput
                    {...params}
                    label="App Types"
                    required
                    placeholder=""
                  />
                )}
              />
            </>
          ) : (
            "Loading..."
          )}
        </Grid>
        {AppTypeRoles.map((item: AppTypeRoles, idz: any) => {
          return (
            <Grid xs={12} sm={12} md={12} item key={idz}>
              <Typography variant="h6">
                <b>{item.appTypeText}</b>
              </Typography>
              {item.roles.map((innerItem: Role, idx: any) => {
                return (
                  <InnerRoleSwitchContainer key={idx}>
                    <Typography>{innerItem.name}</Typography>
                    <Switch
                      {...label}
                      name={innerItem.name}
                      id={innerItem.roleID.toString()}
                      onChange={(event) =>
                        handleInputChange(event, item.appTypeID)
                      }
                      checked={
                        innerItem.hasOwnProperty("isAssignedToApp")
                          ? innerItem.isAssignedToApp
                          : false
                      }
                    />
                  </InnerRoleSwitchContainer>
                );
              })}
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};
