import { ModalErrorMessagesType, handleErrorResponse } from "@genrecp/g2clientportal-common";
import { loginAxios } from "../../services/axios";
import { getToken } from "../slices/authSlice";
import { AppDispatch, store } from "../store";
import { authActionTypes } from "./authActionTypes";
import { Buffer } from 'buffer';
import { persistedStore } from "../..";

const fetchTokenStart = () => ({
    type:authActionTypes.AUTH_START
}); 

const fetchTokenSuccess = (payload:any) => ({
    type:authActionTypes.AUTH_SUCCESS,
    payload:payload
}); 

export const fetchTokenError= (payload:ModalErrorMessagesType|null) => ({
    type:authActionTypes.AUTH_ERROR,
    payload:payload
}); 

export const GetTokenAction = (navigate:any,msalUser:any,accessToken:any, currentRoute:any) => {
    return (dispatch:AppDispatch) => {

        dispatch(getToken(fetchTokenStart()));
        persistedStore.persist();
        loginAxios.get("/Auth",{
            headers: {
              'Authorization': `Bearer ${accessToken}` 
            }
          }).then(function (res) {
            if (res.data.token) {
              const { token } = res.data;

              // GETTING CURRENT USER
              let base64ToString = Buffer.from(token.split('.')[1], "base64").toString();
              let base64ToObject = JSON.parse(base64ToString);
               const payload = {
                token,
                currentUser: base64ToObject,
                msalUser: msalUser,
                msalToken:accessToken
               }
                if(currentRoute === '')
                {
                  navigate("/clientPortal");
                }else{
                  navigate(currentRoute);
                }    
               dispatch(getToken(fetchTokenSuccess(payload)));
            }
          }).catch((e)=>{
            const modalErrorMessages = handleErrorResponse(e);
            dispatch(getToken(fetchTokenError(modalErrorMessages)));
            sessionStorage.clear();
          });
    }
}

export const logout = () => {
  const url = process.env.REACT_APP_PUBLIC_URL;
  persistedStore.pause();
  sessionStorage.clear();
  if(url) window.location.assign(url);
}