import { ModalErrorMessagesType } from "./CommonUtils";

export const handleSuccessResponse = (
    res: any,
    enqueueSnackbar: any,
    error?:string
  ) => {
    const { status } = res || {};
    const errorText = error || `Action was successfully performed!`;
    if (status === 200) {
        enqueueSnackbar(errorText, {
          variant: "success",
          autoHideDuration:3000
        });
    }
  };

  export const handleErrorResponse:(e:any) => ModalErrorMessagesType = (e: any) => {
    const { messages } = e?.response?.data || {};
    // const { status } = e?.response || {};

    const correlationID = e?.response?.headers?.["x-correlation-id"];
    const errorObj: ModalErrorMessagesType = {
      messages:[],
      correlationID : correlationID ? correlationID : undefined
    };

    // if(status === 401) {
    //   errorObj.messages.push("Error! Invalid request")
    //   return errorObj;
    // }

    if (messages && messages.length > 0) {
      const errorString: string[] = messages.map((item: any) => item.message);
      errorObj.messages = errorString;
      return errorObj;
    } 
    
    if (!messages || messages.length === 0) {
      errorObj.messages = ["Error! Invalid request"];
      return errorObj;
    } 
    
    errorObj.messages = ["Error! Invalid request"];
    return errorObj;
  };

  export const showInvalidSnackbar = (
    e: any = null,
    enqueueSnackbar: any,
    message?: string
  ) => {
    
    if(!e && message) {
      enqueueSnackbar(`Error! ${message}`, {
        variant: "error",
      });
      return;
    }
    
    if(e) {
      const { status } = e.response || {};
      if(status === 401) return;
    }
    
    const modalErrorMessages = handleErrorResponse(e);
    enqueueSnackbar(`Error! Invalid Request`, {
      variant: "error",
      errorObject: modalErrorMessages
    });
  };