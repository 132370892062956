import React from "react";
import Grid from "@mui/material/Grid";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  ConfirmationDialog,
  ModalErrors,
  SimpleBackdrop,
  Toggle,
  comparatorForDate,
  formatDateForGrid,
  handleErrorResponse,
  handleSuccessResponse,
  showInvalidSnackbar,
  useConfirm,
  useRibbonSnackbar,
} from "@genrecp/g2clientportal-common";
import Typography from "@mui/material/Typography";
import LogsGrid from "./LogsGrid";
import { commonAxios } from "../../../services/axios";
import { monthYearFormatter } from "@genrecp/g2clientportal-genbill/src/bills/clientView/utils";
import {
  AgGridClasses,
  MuiStyledSwitch as Switch,
} from "@genre/g2common-theme";
import {
  GridContainer,
  LandingHeader,
  ViewSchema,
} from "../../../clientPortalUtils";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { AggridWrapper } from "@genre/common-wrapper-aggrid";
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";

type GridView = "LOGS" | "DATASYNC";

function GenBillDataSync() {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { enqueueSnackbar } = useRibbonSnackbar();
  const [modalErrors, setModalErrors] = React.useState<ModalErrors>({
    errors: {messages:[]},
    ErrorModelOpen: false,
  });
  const [apiLoading, setApiLoading] = React.useState(false);
  const [monthEndDate, setMonthEndDate] = React.useState<string>("");
  const { isConfirmed } = useConfirm();
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [gridView, setGridView] = React.useState<GridView>("DATASYNC");
  const [autoSendEmailFlag, setAutoSendEmailFlag] = React.useState(false);
  const [showSideMenu, setShow] = React.useState<boolean>(false);

  const createdBy = useSelector((state: RootState) => state?.auth?.currentUser);

  const tableName = "BillingPayload";

  const manualExecution = async () => {

    if (gridView === "LOGS" && !monthEndDate) {
      enqueueSnackbar(`Error! Month end date can't be empty.`, {
        variant: "error",
      });
      return;
    }

    const appendText = gridView === "LOGS" ? ` for month end date ${monthYearFormatter(
      monthEndDate
    )}` : `?`;

    const flag: boolean = await isConfirmed(
      `Do you want to run manual execution${appendText}`
    );

    if (!flag) return;

    setIsLoading(true);

    const url =
      gridView === "DATASYNC"
        ? `/DataSync/G2ApplicationAPIBrokerBilling/All?isEmailSend=${autoSendEmailFlag}`
        : `/DataSync/G2ApplicationAPIBrokerBilling/BillProcessing?MonthEndDate=${monthEndDate}&isEmailSend=${autoSendEmailFlag}`;

    commonAxios
      .get(url)
      .then((res: any) => {
        const { status } = res || {};
        if (status === 200) {
          handleSuccessResponse(
            res,
            enqueueSnackbar,
            `Manual execution action has been performed. Please wait for the logs to appear before triggering another action.`
          );
          setGridView("LOGS");
        }
        setIsLoading(false);
      })
      .catch((e: any) => {
        setIsLoading(false);
        showInvalidSnackbar(e, enqueueSnackbar);
      });
  };

  function getColumnDefs(view: ViewSchema | undefined) {
    return [
      {
        field: "createdDate",
        colId: "createdDate",
        headerName: "Date",
        flex: 1,
        filter: "agDateColumnFilter",
        valueGetter: function (params: any) {
          return formatDateForGrid(params.data.createdDate);
        },
        filterParams: {
          filterOptions: ["equals"],
          comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
            return comparatorForDate(filterLocalDateAtMidnight, cellValue);
          },
          suppressAndOrCondition: true,
        },
      },
      {
        flex: 1,
        field: "triggeredBy",
        colId: "triggeredBy",
        headerName: "Triggered By",
        filter: "agTextColumnFilter",
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: ["contains"],
        },
      },
      {
        flex: 1,
        field: "status",
        colId: "status",
        headerName: "Status",
        filter: "agSetColumnFilter",
        filterParams: {
          filterOptions: ["equals"],
          values: ["Failed", "Completed"],
        },
      },
      {
        flex: 1,
        field: "endTime",
        colId: "endTime",
        headerName: "Duration",
        filter: false,
        valueGetter: function (params: any) {
          let startTime = new Date(params.data.startTime);
          let endTime = new Date(params.data.endTime);
          let difference = endTime.getTime() - startTime.getTime();
          difference = difference / 1000;
          let hourDifference = Math.floor(difference / 3600);
          difference -= hourDifference * 3600;
          let minuteDifference = Math.floor(difference / 60);
          difference -= minuteDifference * 60;

          return `${hourDifference === 0 ? "" : hourDifference + " h "}${
            minuteDifference === 0 ? "" : minuteDifference + " m "
          } ${Math.round(difference)} s`;
        },
      },
      {
        flex: 4,
        field: "note",
        colId: "note",
        headerName: "Note",
        filter: "agTextColumnFilter",
        tooltipField: "note",
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: ["contains"],
        },
      },
    ];
  }

  const getGridRowsData = (
    pagination: Object,
    filterModel: Object,
    sortModel: Array<Object>,
    selectedView: ViewSchema | undefined
  ) => {
    const newObj: any = { ...filterModel };
    newObj.qTableName = tableName;

    const obj: any = {
      ...pagination,
      ...newObj,
      orderBy: sortModel
        .map((model: any) => model.colId + " " + model.sort)
        .join(", "),
    };
    if (obj.orderBy === "") delete obj.orderBy;
    return commonAxios.post("/DataSyncLog/GetLogs", obj).then((data: any) => {
      return data.data;
    });
  };

  const getMonthEndFilters = React.useCallback(() => {
    setApiLoading(true);
    commonAxios
      .get(`/DataSyncLog/GetMonthEndDate`)
      .then((res: any) => {
        setApiLoading(false);
        const { data } = res || {};
        data && setMonthEndDate(data);
      })
      .catch((e) => {
        setApiLoading(false);
        console.log(e);
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    getMonthEndFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    payload: GridView
  ) => {
    setGridView((prevState) => {
      return prevState === "LOGS" ? "DATASYNC" : "LOGS";
    });
  };

  return (
    <Grid container spacing={2}>
      <SimpleBackdrop open={apiLoading} />
      <ConfirmationDialog />
      <Grid item md={12} display={"flex"} justifyContent={"space-between"}>
        <Grid md={6} item display={"flex"} gap={2} alignItems={"center"}>
          <Toggle setShow={setShow} />
          <Typography variant="displaySmall">
            {gridView === "DATASYNC"
              ? "Broker Billing Data Synchronization"
              : "Bill Processing Log"}{" "}
          </Typography>
        </Grid>
        <Grid
          md={6}
          item
          display={"flex"}
          justifyContent={"end"}
          alignItems={"center"}
          gap={2}
        >
          <Typography variant="labelMedium">Auto Send Emails</Typography>
          <Switch
            {...label}
            checked={autoSendEmailFlag}
            onChange={() => setAutoSendEmailFlag(!autoSendEmailFlag)}
          />
          <LoadingButton
            onClick={() => manualExecution()}
            loading={isLoading}
            variant="contained"
            disabled={gridView === "LOGS" && !monthEndDate}
          >
            Manual Execution
          </LoadingButton>
        </Grid>
      </Grid>
      {showSideMenu ? (
        <Grid item md={2}>
          <List
            dense
            sx={{
              width: "100%",
              maxWidth: "100%",
              bgcolor: "background.paper",
            }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListItemButton
                sx={{
                  background: "#3A5BAA",
                  "&:hover": {
                    background: "#002B73",
                  },
                }}
              >
                <ListItemText
                  primary={
                    <Typography variant="bodySmall" color={"#fff"}>
                      Data Synchronization
                    </Typography>
                  }
                />
              </ListItemButton>
            }
          >
            <ListItem disablePadding>
              <ListItemButton
                selected={gridView === "DATASYNC"}
                onClick={(event) => handleListItemClick(event, "DATASYNC")}
              >
                <ListItemText
                  primary={
                    <Typography variant="bodySmall">
                      Broker Billing Data Sync
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                selected={gridView === "LOGS"}
                onClick={(event) => handleListItemClick(event, "LOGS")}
              >
                <ListItemText
                  primary={
                    <Typography variant="bodySmall">Bill Logs</Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
            <Divider />
          </List>
        </Grid>
      ) : null}
      {gridView === "LOGS" ? (
        <Grid item md={!showSideMenu ? 12 : 10}>
          <LogsGrid />
        </Grid>
      ) : (
        <Grid item md={!showSideMenu ? 12 : 10}>
          <LandingHeader>
            <GridContainer>
              <AggridWrapper
                gridClassName={`ag-theme-alpine ${AgGridClasses["ag-theme-alpine"]}`}
                getColumnDefs={getColumnDefs}
                getGridRowsData={getGridRowsData}
                dashboardName={""}
                enableUserViews={false}
                enableSystemViews={false}
                landingPage={"ActionLogScreen"}
                views={false}
                gridProps={{
                  tooltipMouseTrack: true,
                  tooltipShowDelay: 0,
                }}
                currentUser={{
                  userName: createdBy && createdBy.id ? createdBy.id : "",
                  email: "",
                  fullName: "",
                }}
                gridHeight={window.innerHeight - 225}
                rowHeight={25}
                hideHeader
                headerHeight={50}
              ></AggridWrapper>
            </GridContainer>
          </LandingHeader>
        </Grid>
      )}
    </Grid>
  );
}

export default GenBillDataSync;
