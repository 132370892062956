import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridClasses } from "@genre/g2common-theme";
import { commonAxios } from "../../../services/axios";

import { Grid } from "@mui/material";
import {
  GridContainer,
  LandingHeader,
  ViewSchema,
} from "../../../clientPortalUtils";
import { AggridWrapper } from "@genre/common-wrapper-aggrid";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import {
  comparatorForDate,
  formatDateForGrid,
} from "@genrecp/g2clientportal-common";

const LogsGrid = () => {
  const createdBy = useSelector((state: RootState) => state?.auth?.currentUser);

  function getColumnDefs(view: ViewSchema | undefined) {
    return [
      {
        field: "createdDate",
        colId: "createdDate",
        headerName: "Date",
        filter: "agDateColumnFilter",
        valueGetter: function (params: any) {
          return formatDateForGrid(params.data.createdDate);
        },
        filterParams: {
          filterOptions: ["equals"],
          comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
            return comparatorForDate(filterLocalDateAtMidnight, cellValue);
          },
          suppressAndOrCondition: true,
        },
      },
      {
        field: "billID",
        colId: "billID",
        flex: 2,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
      },
      {
        field: "logText",
        colId: "logText",
        flex: 2,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
      },
      { field: "jobID", colId: "jobID", flex: 1, filter: false },
      {
        field: "exception",
        colId: "exception",
        flex: 3,
        filter: "agTextColumnFilter",
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: ["contains"],
        },
        tooltipField: "exception",
      },
    ];
  }
  const getGridRowsData = (
    pagination: Object,
    filterModel: Object,
    sortModel: Array<Object>,
    selectedView: ViewSchema | undefined
  ) => {
    const newObj: any = { ...filterModel };

    const obj: any = {
      ...pagination,
      ...newObj,
      orderBy: sortModel
        .map((model: any) => model.colId + " " + model.sort)
        .join(", "),
    };
    if (obj.orderBy === "") delete obj.orderBy;
    return commonAxios
      .post("/DataSyncLog/GetBillProcessingLogs", obj)
      .then((data: any) => {
        return data.data;
      });
  };
  return (
    <Grid item md={12}>
      <LandingHeader>
        <GridContainer>
          <AggridWrapper
            gridClassName={`ag-theme-alpine ${AgGridClasses["ag-theme-alpine"]}`}
            getColumnDefs={getColumnDefs}
            getGridRowsData={getGridRowsData}
            dashboardName={""}
            enableUserViews={false}
            enableSystemViews={false}
            landingPage={"ActionLogScreen"}
            views={false}
            gridProps={{
              tooltipMouseTrack: true,
              tooltipShowDelay: 0,
            }}
            currentUser={{
              userName: createdBy && createdBy.id ? createdBy.id : "",
              email: "",
              fullName: "",
            }}
            gridHeight={window.innerHeight - 225}
            rowHeight={25}
            hideHeader
            headerHeight={50}
          ></AggridWrapper>
        </GridContainer>
      </LandingHeader>
    </Grid>
  );
};

export default LogsGrid;
