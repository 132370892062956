import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { ColDef, GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useRibbonSnackbar, EditChildRenderer } from "@genrecp/g2clientportal-common";
import { authAxios } from "../../../../../services/axios";
import {
  ClientLocationAppType,
  clientLocationArrayType,
  ApptypeType,
} from "../utils";
import {
  appTypeLookup,
} from "../../appRolePermission/appRolePermissionUtils";
import { filterColumnsObjCreator } from "@genrecp/g2clientportal-common";
import {AgGridClasses} from "@genre/g2common-theme"

let gridApi: any;
let columnApi: any;

interface Props {
  request: ClientLocationAppType;
  setRequest: (params: ClientLocationAppType) => void;
  clientLocationArray: clientLocationArrayType[];
  appTypeArray: ApptypeType[];
}

export const ClientLocationGrid: React.FC<Props> = (props) => {
  const { request, setRequest, clientLocationArray, appTypeArray } = props;
  const token = useSelector((state: RootState) => state?.auth?.token);
  const { enqueueSnackbar } = useRibbonSnackbar();
  const gridStyle = React.useMemo(() => ({ height: 505, width: "100%" }), []);
  
  const onRowEditSelected = (params:any) => {
    
    const {clientLocationID, appTypeID, active, createdDate , createdBy,clientLocationAppTypeID } = params.data || {};
 
    const newClientLocationIDObj =  clientLocationArray.find((item:clientLocationArrayType) => item.clientLocationID === clientLocationID);

    if(newClientLocationIDObj){
      setRequest({
        ...request,
        clientLocationAppTypeID,
        clientLocationID:newClientLocationIDObj,
        appTypeID,
        active,
        createdBy,
        createdDate,
        updatetoggler:true
      })
    } else {
      enqueueSnackbar(`Error! Invalid Request`, {
        variant: "error",
      });
    }
  }

  const defaultColDef: ColDef = {
    cellClass: "cell-wrap-text",
    cellStyle: { whiteSpace: "normal" },
    sortable: true,
    resizable: true,
    floatingFilter: true,
  };
  const [colDefs] = React.useState<ColDef[]>([
    {
      field: "clientLocationAppTypeID",
      colId: "clientLocationAppTypeID",
      headerName: "clientLocationAppTypeID",
      sort: "desc",
      hide: true,
    },
    {
      field: "clientGroupID",
      colId: "clientGroupID",
      headerName: "Client Group ID",
      filter: "agTextColumnFilter",
      flex:.8,
      filterParams:{
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
    },
    {
      field: "clientLocationID",
      colId: "clientLocationID",
      headerName: "Client LocationID",
      filter: "agTextColumnFilter",
      flex:.8,
      filterParams:{
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
    },
    {
      field: "businessName",
      colId: "businessName",
      headerName: "Business Name",
      filter: "agTextColumnFilter",
      flex:1,
      filterParams:{
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
    },
    {
      field: "stateCode",
      colId: "stateCode",
      headerName: "State Code",
      filter: "agTextColumnFilter",
      flex:.6,
      filterParams:{
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
    },
    {
      field: "appTypeID",
      colId: "appTypeID",
      headerName: "App Type",
      flex:1,
      filter: "agSetColumnFilter",
      filterParams: {
        values: appTypeArray.map((i: any) => i.appTypeText),
        suppressAndOrCondition: true,
      },
      valueGetter: function (params: any) {
        return appTypeLookup(appTypeArray, params.data.appTypeID);
      },
    },
    {
      field: "active",
      colId: "active",
      headerName: "Active",
      filter: "agSetColumnFilter",
      filterParams: {
        values: [true, false],
        suppressAndOrCondition: true,
      },
      flex:.5
    },
    {
      headerName: '',
      onCellClicked: onRowEditSelected,
      cellRenderer: 'EditChildRenderer',
      flex:.3
    },
  ]);

  const frameworkComponents = {
    EditChildRenderer: EditChildRenderer
  };

  const ensureNonEmptyString = (str: any) =>
    typeof str === "string" && str.trim() !== "";

  function getSortedColumns(columnApi: any) {
    return columnApi
      ?.getColumnState()
      .filter((cs: any) => ensureNonEmptyString(cs.sort));
  }

  const ServerSideDatasource = () => {
    return {
      getRows: async function (params: any) {
        let filterModel = gridApi.getFilterModel();
        let filterCount = Object.keys(gridApi.getFilterModel()).length;
        let sortCount = getSortedColumns(columnApi).length;
        let searchDataObj: any = {
          pageNumber: params.request.endRow / 10,
          pageSize: 10,
        };
        if (filterCount > 0) {
          let gridOpeartionProps = [];
          // clientLocationID
          if (filterModel.clientLocationID !== undefined) {
            let clientLocationIDObj = filterColumnsObjCreator(
              "clientLocationID",
              filterModel.clientLocationID.filter,
              "string"
            );
            gridOpeartionProps.push(clientLocationIDObj);
          }
          // businessName
          if (filterModel.businessName !== undefined) {
            let businessNameObj = filterColumnsObjCreator(
              "businessName",
              filterModel.businessName.filter,
              "string"
            );
            gridOpeartionProps.push(businessNameObj);
          } 
          // stateCode
          if (filterModel.stateCode !== undefined) {
            let stateCodeObj = filterColumnsObjCreator(
              "stateCode",
              filterModel.stateCode.filter,
              "string"
            );
            gridOpeartionProps.push(stateCodeObj);
          }   
          // clientGroupID
          if (filterModel.clientGroupID !== undefined) {
            let clientGroupIDObj = filterColumnsObjCreator(
              "clientGroupID",
              filterModel.clientGroupID.filter,
              "string"
            );
            gridOpeartionProps.push(clientGroupIDObj);
          }                            
          // appTypeID
          if (
            filterModel.appTypeID !== undefined &&
            filterModel.appTypeID.values.length > 0
          ) {
            let finalappTypeID: string[] = [];
            filterModel.appTypeID.values.forEach((element: string) => {
              finalappTypeID.push(appTypeLookup(appTypeArray, element));
            });
            let appTypeIDObj = filterColumnsObjCreator(
              "appTypeID",
              finalappTypeID,
              "int[]"
            );
            gridOpeartionProps.push(appTypeIDObj);
          }
          // active
          if (
            filterModel.active !== undefined &&
            filterModel.active.values.length > 0
          ) {
            let activeObj = filterColumnsObjCreator(
              "active",
              filterModel.active.values,
              "bool"
            );
            gridOpeartionProps.push(activeObj);
          }
          if (gridOpeartionProps.length)
            searchDataObj["filterColumns"] = gridOpeartionProps;
        }
        if (sortCount > 0) {
          const sortCol = getSortedColumns(columnApi)[0];
          let colID = sortCol.colId;
          if(colID === "appTypeID") {
            colID = "appTypeName";
          }
          searchDataObj.orderBy = colID + " " + sortCol.sort;
        }

        if (token) {
          authAxios
            .post(
              "/GetClientLocationAppTypes",
              {
                gridOperationsProps: searchDataObj,
              }
            )
            .then((res: any) => {
              var totalRows = -1;
              if (res.data !== null && res.data !== undefined) {
                if (res.data.length < 10) {
                  totalRows = params.request.startRow + res.data.length;
                }
                params.successCallback(res.data, totalRows);
              } else {
                params.successCallback([], totalRows);
              }
            })
            .catch((e: any) => {
              console.log(e.message);
            });
        }
      },
    };
  };

  const onGridReady = (params: GridReadyEvent) => {
    gridApi = params.api;
    columnApi = params.columnApi;
    var datasource = ServerSideDatasource();
    gridApi.setServerSideDatasource(datasource);
  };

  const refreshGrid = (gridApi: any) => {
    if (gridApi) {
      var datasource = ServerSideDatasource();
      gridApi.setServerSideDatasource(datasource);
    }
  };
  React.useEffect(() => {
    refreshGrid(gridApi);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request.refreshToggler]);

  React.useEffect(() => {
    return () => {
      gridApi && gridApi.destroy();
      gridApi = null
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
          <div style={gridStyle} className={`ag-theme-alpine ${AgGridClasses['ag-theme-alpine']}`}>
            <AgGridReact
              rowHeight={35}
              defaultColDef={defaultColDef}
              columnDefs={colDefs}
              onGridReady={onGridReady}
              pagination={true}
              cacheBlockSize={10}
              paginationPageSize={10}
              rowModelType={"serverSide"}
              suppressServerSideInfiniteScroll={false}
              animateRows={true}
              components={frameworkComponents}
            ></AgGridReact>
          </div>
  );
};
