import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import commonReducer from "./slices/commonSlice";
import lossReportingReducer from "./slices/lossReportingSlice";
import genbillReducer from "./slices/genBillSlice";
import confirmationReducer from "./slices/confirmationSlice";
import { persistReducer } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'

const rootReducer = combineReducers({
    auth:authReducer,
    common:commonReducer,
    lossreporting:lossReportingReducer,
    genbill:genbillReducer,
    confirmation:confirmationReducer,
});

const persistConfig = {
    key: 'root',
    storage: storageSession,
    whitelist: ['common','lossreporting','auth'] 
};

export const persistedReducer  = persistReducer(persistConfig,rootReducer);