import axios from "axios";
import { logout } from "../redux/auth/authActionCreator";

export const loginAxios = axios.create({
    baseURL: process.env.REACT_APP_SECURITY_API
})

export const authAxios = axios.create({
    baseURL: process.env.REACT_APP_SECURITY_API
})

export const LossReportAxios = axios.create({
    baseURL: process.env.REACT_APP_LOSSREPORTING_API
})

export const commonAxios = axios.create({
    baseURL: process.env.REACT_APP_COMMON_API
})

export const delegatedAxios = axios.create({
    baseURL: process.env.REACT_APP_DELEGATED_API
})

export const formDataCommonAxios = axios.create({
    baseURL: process.env.REACT_APP_COMMON_API
})

export const brokerBillingAxios = axios.create({
    baseURL: process.env.REACT_APP_BROKERBILLING_API
})

var _count = 0;

authAxios.interceptors.request.use(
    (config: any) => {
        config.headers = config.headers ?? {};
        const token = JSON.parse(JSON.parse(sessionStorage.getItem("persist:root") ?? "{}")?.auth ?? "{}")?.token;
        const msalToken = JSON.parse(JSON.parse(sessionStorage.getItem("persist:root") ?? "{}")?.auth ?? "{}")?.msalToken;

        if (token) {
            config.headers['Authorization'] = `Bearer ${msalToken}`;
            config.headers['InternalAuthToken'] = token;
        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

LossReportAxios.interceptors.request.use(
    (config: any) => {
        config.headers = config.headers ?? {};
        const token = JSON.parse(JSON.parse(sessionStorage.getItem("persist:root") ?? "{}")?.auth ?? "{}")?.token
        const msalToken = JSON.parse(JSON.parse(sessionStorage.getItem("persist:root") ?? "{}")?.auth ?? "{}")?.msalToken;

        if (token) {
            config.headers['Authorization'] = `Bearer ${msalToken}`;
            config.headers['InternalAuthToken'] = token;
        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

commonAxios.interceptors.request.use(
    (config: any) => {
        config.headers = config.headers ?? {};
        const token = JSON.parse(JSON.parse(sessionStorage.getItem("persist:root") ?? "{}")?.auth ?? "{}")?.token
        const msalToken = JSON.parse(JSON.parse(sessionStorage.getItem("persist:root") ?? "{}")?.auth ?? "{}")?.msalToken;

        if (token) {
            config.headers['Authorization'] = `Bearer ${msalToken}`;
            config.headers['InternalAuthToken'] = token;
        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

delegatedAxios.interceptors.request.use(
    (config: any) => {
        config.headers = config.headers ?? {};
        const token = JSON.parse(JSON.parse(sessionStorage.getItem("persist:root") ?? "{}")?.auth ?? "{}")?.token
        const msalToken = JSON.parse(JSON.parse(sessionStorage.getItem("persist:root") ?? "{}")?.auth ?? "{}")?.msalToken;

        if (token) {
            config.headers['Authorization'] = `Bearer ${msalToken}`;
            config.headers['InternalAuthToken'] = token;
        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

brokerBillingAxios.interceptors.request.use(
    (config: any) => {
        config.headers = config.headers ?? {};
        const token = JSON.parse(JSON.parse(sessionStorage.getItem("persist:root") ?? "{}")?.auth ?? "{}")?.token
        const msalToken = JSON.parse(JSON.parse(sessionStorage.getItem("persist:root") ?? "{}")?.auth ?? "{}")?.msalToken;

        if (token) {
            config.headers['Authorization'] = `Bearer ${msalToken}`;
            config.headers['InternalAuthToken'] = token;
        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

commonAxios.interceptors.response.use(
    function (res: any) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return res;
    },
    function (error: any) {
        if (error?.response?.status === 401 && _count === 0) {
            _count++;
            window.alert("Session has been expired. You need to login.");
            logout();
        }
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    }
);

delegatedAxios.interceptors.response.use(
    function (res: any) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return res;
    },
    function (error: any) {
        if (error?.response?.status === 401 && _count === 0) {
            _count++;
            window.alert("Session has been expired. You need to login.");
            logout();
        }
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    }
);

formDataCommonAxios.interceptors.request.use(
    function (config: any) {
        config.headers = config.headers ?? {};
        const token = JSON.parse(JSON.parse(sessionStorage.getItem("persist:root") ?? "{}")?.auth ?? "{}")?.token
        const msalToken = JSON.parse(JSON.parse(sessionStorage.getItem("persist:root") ?? "{}")?.auth ?? "{}")?.msalToken;

        if (token) {
            config.headers['Authorization'] = `Bearer ${msalToken}`;
            config.headers['InternalAuthToken'] = token;
            config.headers['Content-Type'] = "multipart/form-data";
        }
        return config;
    },
    function (error: any) {
        return Promise.reject(error);
    }
);

formDataCommonAxios.interceptors.response.use(
    function (res: any) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return res;
    },
    function (error: any) {
        if (error?.response?.status === 401 && _count === 0) {
            // _count++;
            // window.alert("Session has been expired. You need to login.");
            // logout();
        }
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    }
);

authAxios.interceptors.response.use(
    function (res: any) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return res;
    },
    function (error: any) {
        if (error?.response?.status === 401 && _count === 0) {
            _count++;
            window.alert("Session has been expired. You need to login.");
            logout();
        }
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    }
);

LossReportAxios.interceptors.response.use(
    function (res: any) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return res;
    },
    function (error: any) {
        if (error?.response?.status === 401 && _count === 0) {
            _count++;
            window.alert("Session has been expired. You need to login.");
            logout();
        }
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    }
);

brokerBillingAxios.interceptors.response.use(
    function (res: any) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return res;
    },
    function (error: any) {
        if (error?.response?.status === 401 && _count === 0) {
            _count++;
            window.alert("Session has been expired. You need to login.");
            logout();
        }
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    }
);
